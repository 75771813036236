import type { FunctionComponent, SVGProps } from "react";
import type { States } from "../data/data"; // Import the States interface
import { Combobox, type ListItem } from "./combobox"; // Assuming this is the path to your existing Combobox component

interface CombinedComboboxProps {
  list: States[]; // Change the type of list to States[]
  label: string;
  LabelIcon: FunctionComponent<SVGProps<SVGSVGElement>>;
  firstValue: string | undefined;
  secondValue: string | undefined;
  onChangeFirst: (value: string) => void;
  onChangeSecond: (value: string) => void;
  color?: "primary" | "secondary";
}

export function CombinedCombobox({
  list,
  firstValue,
  secondValue,
  onChangeFirst,
  onChangeSecond,
  color,
}: CombinedComboboxProps): JSX.Element {
  const getSecondListItems = (first: string): ListItem[] => {
    const selectedState = list.find((state) => state.name === first);
    if (selectedState) {
      return selectedState.cities.map((city) => ({
        value: city.name,
        label: city.name,
      }));
    }
    return [];
  };

  const secondListItems = getSecondListItems(firstValue ?? "");

  return (
    <div className="flex flex-row gap-2 items-end">
      <div className="w-[45%]">
        <Combobox
          listItems={list.map((state) => ({
            value: state.name,
            label: state.name,
          }))} // Map States to ListItem
          size="sm"
          label="Localização"
          LabelIcon={() => null} // No icon for individual dropdowns
          value={firstValue}
          onChange={(value) => {
            onChangeFirst(value);
            onChangeSecond(""); // Clear the second value when the first state changes
          }}
          color={color}
          emptyText="Estado"
        />
      </div>
      <div className="w-[55%]">
        <Combobox
          size="sm"
          listItems={secondListItems}
          label=""
          LabelIcon={() => null} // No icon for individual dropdowns
          value={secondValue}
          onChange={onChangeSecond}
          color={color}
          disabled={!firstValue}
          emptyText="Cidade"
        />
      </div>
    </div>
  );
}
