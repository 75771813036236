import { Link } from "@remix-run/react";

interface SeoListProps {
  title: string;
  items: Record<string, string>;
  icon: string;
  horizontal?: boolean;
}

export function SeoList({
  title,
  items,
  icon,
  horizontal,
}: SeoListProps): JSX.Element {
  return (
    <nav
      className="flex flex-col gap-4 md:gap-6"
      title={title}
      aria-label={title}
    >
      <div className="flex flex-row items-center gap-3">
        <img
          className="h-6 w-6 md:h-8 md:w-8 text-neutral-700"
          loading="eager"
          alt=""
          src={icon}
        />
        <h3 className="text-base md:text-xl font-semibold text-neutral-700">
          {title}
        </h3>
      </div>
      <div
        className={`flex flex-col gap-3 text-left text-sm text-primary-600 font-plain-button-small flex-wrap ${horizontal ? "md:flex-row" : ""}`}
      >
        {Object.entries(items).map(([key, value]) => (
          <Link
            to={value}
            key={key}
            className="relative leading-[20px] md:leading-[24px] font-medium"
            reloadDocument
          >
            {key}
          </Link>
        ))}
      </div>
    </nav>
  );
}
